import { Component, Inject } from "@angular/core";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatRadioModule,
} from "@angular/material";
import { Router } from "@angular/router";

import { SolicitudDialogComponent } from "../solicitud-dialog/solicitud-dialog.component";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { EntornoService } from "../../../entorno/entorno.service";

import { DcardService } from "../../../portal/dcard/dcard.service";
import { AlertDialogComponent } from "../alert-dialog/alert-dialog.componetn";

@Component({
  selector: "movimientos-dialog",
  templateUrl: "movimientos-dialog.component.html",
  providers: [DcardService],
})
export class MovimientoDialogComponent {
  currentMonth: any;

  public title: string;
  public yesOption: string;
  public width: string;
  public height: string;

  public loading: boolean = true;

  public fecha: string;

  public saldoDisponible: any;
  public saldoDisponibleF: any;

  public user: any;

  public carga: any = null;

  content = {
    title: "Apreciado usuario DIGOPAGO.",
    subtitle:
      "Seleccione una de las opciones para recibir sus movimientos por correo y luego presione el botón confirmar",
    solicitud: "",
  };
  monthSelector = false;

  optionT: any = null;
  tarjeta = "";
  fee = "";
  answer = "";

  monthSelected = 1;
  dateSelect = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MovimientoDialogComponent>,
    private service: DcardService,
    public dialog: MatDialog,
    private entorno: EntornoService,
    private router: Router
  ) {
    dialogRef.disableClose = true;
    this.title = data.title;
    this.yesOption = data.mensajeBoton;
    console.log(data.dcard);
    this.tarjeta = this.getCard();

    this.renderMonth();
  }

  onClick(option: number) {
    this.dialogRef.close(option);
  }

  getDcardStatus() {
    this.carga = "Cargando...";
    this.service
      .obtenerDCardDatos(this.entorno.auth()["user"].documento)
      .subscribe(
        (result) => {
          // this._services.userData.dcardStatus = result.staPan;
          // this._services.userData.dcard = result.pan;
          // this._services.userData.dcardId = result.id;
          console.log("dcardstatus", result.staPan);
          this.carga = null;
        },
        (err) => {
          this.carga = null;
          if (err.error.trim() === "14 Tarjeta Invalida") {
            // this._services.userData.dcardStatus = 0;
            console.log("14 Tarjeta Invalida");
          } else {
            console.log(err);
          }
        }
      );
  }

  getCard() {
    const card = this.data.dcard.pan;
    const first = card.substring(0, 4);
    const last = card.substring(card.length - 4);
    return `${first}-****-****-${last}`;
  }

  getFee() {
    this.carga = "Cargando...";

    this.service.getDcardFee().subscribe(
      (resp) => {
        this.carga = null;
        this.fee = resp.valor;
      },
      (err) => {
        this.carga = null;
        console.log(err);
        this.dialog
          .open(AlertDialogComponent, {
            data: {
              title: "Aviso",
              message:
                "En estos momentos este servicio no esta disponible, intente mas tarde",
              yes: "Cerrar",
            },
          })
          .afterClosed();
      }
    );
  }

  aceptar() {
    let month = 0;
    if (this.monthSelector) {
      month = this.monthSelected;
    }
    this.carga = "Cargando...";
    this.service.generateEdoCuenta(month).subscribe(
      (resp) => {
        this.dialog
          .open(AlertDialogComponent, {
            data: {
              title: "Aviso",
              message: "Tus movimientos fueron enviados a tu correo",
              yes: "Cerrar",
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.carga = null;
            this.router.navigate(["/portal/dashboard"]);
            this.dialog.closeAll();
          });
      },
      (err) => {
        this.carga = null;
        this.dialog
          .open(AlertDialogComponent, {
            data: {
              title: "Aviso",
              message: err.error.mensaje,
              yes: "Cerrar",
            },
          })
          .afterClosed();
        console.log(err);
      }
    );
  }

  movementSelection(e) {
    if (e === 0) {
      this.monthSelector = false;
    } else {
      this.monthSelector = true;
    }
  }

  renderMonth() {
    this.dateSelect = [];
    for (let index = 1; index <= 4; index++) {
      let d = new Date();
      d.setMonth(d.getMonth() - index);
      let previousMonth = d.toLocaleString("es-VE", { month: "long" });
      let year = d.getFullYear();
      this.dateSelect.push({
        month: previousMonth,
        year: year,
        val: index,
        checked: index === 1 ? true : false,
      });
    }
  }

  selectMonth(event) {
    this.monthSelected = event.value;
  }
}
