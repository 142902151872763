import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { RouterModule } from "@angular/router";

import { SharedService } from "./shared.service";
import { CompletedDialogComponent } from "./dialogs/completed-dialog.component";
import { ConfirmationDialogComponent } from "./dialogs/confirmation-dialog.component";
import { ErrorRecaudoDialogComponent } from "./dialogs/error-recaudo-dialog.component";

import { AlertDialogComponent } from "./dialogs/alert-dialog/alert-dialog.componetn";
import { DetalleOperacionDialogComponent } from "./dialogs/detalle-operacion/detalle-operacion-dialog.component";
import { DetalleRetiroDialogComponent } from "./dialogs/detalle-retiro/detalle-retiro-dialog.component";
import { CargaSaldoDialogComponent } from "./dialogs/carga-saldo-dialog/carga-saldo-dialog.component";
import { SolicitudDialogComponent } from "./dialogs/solicitud-dialog/solicitud-dialog.component";
import { IntroSolicitudDialogComponent } from "./dialogs/intro-solicitud-dialog/intro-solicitud-dialog.component";
import { ContratoDialogComponent } from "./dialogs/contrato-dialog/contrato-dialog.component";
import { ActivacionDialogComponent } from "./dialogs/activacion-dialog/activacion-dialog.component";
import { BloqueoDialogComponent } from "./dialogs/bloqueo-dialog/bloqueo-dialog.component";
import { MovimientoDialogComponent } from "./dialogs/movimientos-dialog/movimientos-dialog.component";
import { DetalleOpRecargaDialogComponent } from "./dialogs/detalle-op-recarga/detalle-op-recarga-dialog.component";

import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
} from "./accordion";
import { MaterialModule } from "./material/material.module";
import { SpinnerComponent } from "./spinner/spinner.component";
import { SortColumnsComponent } from "./sort-columns/sort-columns.component";
import { TerminosComponent } from "./terminos";

import { NgQrScannerModule } from "angular2-qrscanner";
import { EscanerQRComponent } from "./escaner-qr/escaner-qr.component";
import { OrigenCargaRecaudoDialogComponent } from "./dialogs/origen-carga-recaudo-dialog";
import { TomarFotoRecaudoDialogComponent } from "./dialogs/tomar-foto-recaudo-dialog";
import { WebcamComponent } from "ngx-webcam";
import { WebcamModule } from "ngx-webcam";
import { DirectivesModule } from "../directivas/directives.module";

import { SimpleMaskModule } from "ngx-ion-simple-mask";
import { TermsvtComponent } from './dialogs/termsvt/termsvt.component';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    SortColumnsComponent,
    EscanerQRComponent,
    TerminosComponent,
    ConfirmationDialogComponent,
    ErrorRecaudoDialogComponent,
    CompletedDialogComponent,
    AlertDialogComponent,
    DetalleOperacionDialogComponent,
    DetalleOpRecargaDialogComponent,
    SolicitudDialogComponent,
    IntroSolicitudDialogComponent,
    MovimientoDialogComponent,
    BloqueoDialogComponent,
    ActivacionDialogComponent,
    ContratoDialogComponent,
    DetalleRetiroDialogComponent,
    CargaSaldoDialogComponent,
    OrigenCargaRecaudoDialogComponent,
    TomarFotoRecaudoDialogComponent,
    TermsvtComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgQrScannerModule,
    WebcamModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleMaskModule,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    SortColumnsComponent,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    RouterModule,
    CompletedDialogComponent,
    AlertDialogComponent,
    DetalleOperacionDialogComponent,
    DetalleOpRecargaDialogComponent,
    SolicitudDialogComponent,
    IntroSolicitudDialogComponent,
    MovimientoDialogComponent,
    BloqueoDialogComponent,
    ActivacionDialogComponent,
    ContratoDialogComponent,
    DetalleRetiroDialogComponent,
    CargaSaldoDialogComponent,
    TerminosComponent,
    WebcamModule,
    DirectivesModule,
  ],
  providers: [SharedService],
  entryComponents: [WebcamComponent],
})
export class SharedModule {}
