import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { PagarService } from "../../../portal/pagar/pagar.service";

@Component({
  selector: "carga-saldo-dialog",
  templateUrl: "carga-saldo-dialog.component.html",
  providers: [PagarService],
})
export class CargaSaldoDialogComponent {
  public title: string;
  public mensajeBoton: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CargaSaldoDialogComponent>,
    public dialog: MatDialog,
    private router: Router
  ) {
    dialogRef.disableClose = true;
    this.title = data.title;
    this.mensajeBoton = data.mensajeBoton;

    console.log(this.router);
  }

  onClick(option: number) {
    this.dialogRef.close(option);
  }
}
