import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";

import { SolicitudDialogComponent } from "../solicitud-dialog/solicitud-dialog.component";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { EntornoService } from "../../../entorno/entorno.service";

import { DcardService } from "../../../portal/dcard/dcard.service";
import { AlertDialogComponent } from "../alert-dialog/alert-dialog.componetn";

@Component({
  selector: "activacion-dialog",
  templateUrl: "activacion-dialog.component.html",
  providers: [DcardService],
})
export class ActivacionDialogComponent {
  public title: string;
  public yesOption: string;
  public width: string;
  public height: string;

  public loading: boolean = true;

  public fecha: string;

  public saldoDisponible: any;
  public saldoDisponibleF: any;

  public user: any;

  public carga: any = null;

  content = {
    title:
      "Bienvenido al mundo Digo ahora tendras un instrumento de pago en cualquier punto de venta con su saldo DIGO disponible",
    subtitle: "",
    solicitud: "",
  };

  activationForm: FormGroup = this.fb.group({
    numberCard: [
      "",
      [Validators.required, Validators.minLength(12), Validators.maxLength(16)],
    ],
    expDate: ["", [Validators.required, this.expiredDateValidation]],
    cvv: [
      "",
      [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
    ],
  });

  public sMask = {
    location: {
      mask: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      pattern: { X: "[A-ZÑa-zñ ]" },
    },
    address: {
      mask: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      pattern: { X: "[A-Za-z0-9 ]" },
    },
    name: {
      mask: "XXXXXXXXXXXXXXXXXXXXXXXXXX",
      pattern: { X: "[A-Za-z0-9 ]" },
    },
    concept: {
      mask: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      pattern: { X: "[a-zñA-ZÑ0-9 ]" },
    },
    cardNumber: {
      mask: "XXXXXXXXXXXXXXXX",
      pattern: { X: "[0-9]" },
    },
    phoneNumber: {
      mask: "XXXXXXXXXXX",
      pattern: { X: "[0-9]" },
    },
    expiredDate: {
      mask: "XX/XX",
      pattern: { X: "[0-9, /]" },
    },
    cvv: {
      mask: "XXX",
      pattern: { X: "[0-9]" },
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ActivacionDialogComponent>,
    private service: DcardService,
    public dialog: MatDialog,
    private entorno: EntornoService,
    private router: Router
  ) {
    dialogRef.disableClose = true;
    this.title = data.title;
    this.yesOption = data.mensajeBoton;
  }

  onClick(option: number) {
    this.dialogRef.close(option);
  }

  expiredDateValidation(control: FormControl) {
    const now = new Date();
    now.setMonth(now.getMonth() + 6);
    const nowYear = now.getFullYear().toString();

    const exp = control.value.split("/");
    const exDate = new Date(
      `${nowYear.substring(0, 2)}${exp[1]}-${exp[0]} 00:00:00`
    );

    if (exDate < now) {
      return { wrongExpDate: true };
    }

    return null;
  }

  confirm() {
    this.dialogRef.close();
    this.dialog
      .open(SolicitudDialogComponent, {
        width: "500px",
        data: {
          title: "Solicitud de tarjeta D CARD",
          mensajeBoton: "Cancelar",
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((result) => {
        //this.router.navigate(["/portal/dashboard"]);
      });
  }

  activacion() {
    this.carga = "Cargando...";
    const err = this.service.getInputsErrors(this.activationForm);

    if (err !== "") {
      this.dialog
        .open(AlertDialogComponent, {
          data: {
            title: "Aviso!",
            message: `${err}`,
            yes: "Cerrar",
          },
        })
        .afterClosed();
      return false;
    }

    const cardNumber = this.activationForm.get("numberCard").value;
    const expDate = `${this.activationForm.get("expDate").value.split("/")[1]}${
      this.activationForm.get("expDate").value.split("/")[0]
    }`;

    const cvv = this.activationForm.get("cvv").value;

    this.service.activarDCard(cardNumber, cvv, expDate).subscribe(
      (data) => {
        this.carga = null;
        this.dialogRef.close();
        this.getDcardStatus();

        this.dialog
          .open(AlertDialogComponent, {
            data: {
              title: "Estimado cliente",
              message:
                "Estimado Usuario ya tiene su tarjeta ACTIVA para ser utilizada en cualquier punto de venta a nivel Nacional, DIGO esta contigo",
              yes: "Aceptar",
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(["/portal/dashboard"]);
          });
      },
      (err) => {
        console.log("error", err);
        this.carga = null;
        this.dialog
          .open(AlertDialogComponent, {
            data: {
              title: "Estimado cliente",
              message:
                "Estimado usuario revise los datos de la tarjeta ó si tiene problemas comunique con DIGO a través del 0412-3446729 o por el correo atencionalcliente@digoapgo.com",
              yes: "Cerrar",
            },
          })
          .afterClosed();
      }
    );
  }

  getDcardStatus() {
    this.carga = "Cargando...";
    this.service
      .obtenerDCardDatos(this.entorno.auth()["user"].documento)
      .subscribe(
        (result) => {
          // this._services.userData.dcardStatus = result.staPan;
          // this._services.userData.dcard = result.pan;
          // this._services.userData.dcardId = result.id;
          console.log("dcardstatus", result.staPan);
          this.carga = null;
        },
        (err) => {
          this.carga = null;
          if (err.error.trim() === "14 Tarjeta Invalida") {
            // this._services.userData.dcardStatus = 0;
            console.log("14 Tarjeta Invalida");
          } else {
            console.log(err);
          }
        }
      );
  }
}
