import { NgModule } from "@angular/core";
import { InputValidarDirective } from "./input-validar/input-validar";
import { LimpiarContrasenaDirective } from "./limpiar-campos/limpiar-campos";
import { CampoMontoDirective } from "./campo-monto/campo-monto";
import { PorcentajeDecimalNumberDirective } from "./decimal/decimal.directive";
import { TextoEspaciosDirective } from "./texto-espacios/texto-espacios";
import { SoloNumerosDirective } from "./solo-numeros/solo-numeros";
import { LimpiarCorreoDirective } from "./limpiar-correo/limpiar-correo";
import { SoloTextoDirective } from "./solo-texto/solo-texto";
import { TextoConEspaciosDirective } from "./texto-con-espacios/texto-con-espacios";
import { PasswordDirective } from "./claves/password.directive";
import { EmailDirective } from "./correos/email.directive";
import { LimpiarEspaciosDirective } from "./limpiar-espacios/limpiar-espacios";
import { FilterDirective } from "./filtro/filter.directive";
import { LetrasNumbersOnlyDirective } from "./soloTextNumber-no-espacio/solo-texto-numero";

@NgModule({
  declarations: [
    InputValidarDirective,
    LimpiarContrasenaDirective,
    CampoMontoDirective,
    PorcentajeDecimalNumberDirective,
    TextoEspaciosDirective,
    SoloNumerosDirective,
    LimpiarCorreoDirective,
    SoloTextoDirective,
    TextoConEspaciosDirective,
    PasswordDirective,
    EmailDirective,
    LimpiarEspaciosDirective,
    FilterDirective,
    LetrasNumbersOnlyDirective,
  ],
  imports: [],
  exports: [
    InputValidarDirective,
    LimpiarContrasenaDirective,
    CampoMontoDirective,
    PorcentajeDecimalNumberDirective,
    TextoEspaciosDirective,
    SoloNumerosDirective,
    LimpiarCorreoDirective,
    SoloTextoDirective,
    TextoConEspaciosDirective,
    PasswordDirective,
    EmailDirective,
    LimpiarEspaciosDirective,
    FilterDirective,
    LetrasNumbersOnlyDirective,
  ],
})
export class DirectivesModule {}
