import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";

import { environment } from "../../../environments/environment";

import { EntornoService } from "../../entorno/entorno.service";

import * as CryptoJS from "crypto-js";
import BigNumber from "bignumber.js";

@Injectable()
export class PagarService {
  private seed = "GU7RI16RKCDO5R0CHPPW949MOVXE469Y";
  private timeout = 35000;
  public token: string;
  public usuario: string;

  constructor(
    private http: HttpClient,
    private entornoService: EntornoService
  ) {
    this.token = this.entornoService.auth()["user"].token;
    this.usuario = this.entornoService.auth()["user"].usuario;
  }

  public autorizarVenta(venta: any) {
    venta.monto = this.entornoService.limpiarMonto(venta.monto);
    let auth: any = this.entornoService.auth();

    let firmaSeguridad = CryptoJS.SHA512(
      this.seed +
      auth.user.token +
      venta.identificacion +
      venta.numeroTelefono +
      venta.monto +
      "VES" +
      venta.clave
    )
      .toString()
      .toUpperCase();

    let dataSolicitud = {
      identificacion: venta.identificacion,
      numeroTelefono: venta.numeroTelefono,
      monto: venta.monto,
      moneda: "VES",
      clave: venta.clave,
      firmaDigital: firmaSeguridad,
    };
    return this.http.post(
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliados/" +
      auth.user.usuario +
      "/ventas",
      dataSolicitud,
      {
        headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
        observe: "response",
      }
    );
  }

  /********************************************************************/
  /********************************Limpieza final de campos*******************/
  public limpiarCampo(valor, origen) {
    if (origen == "texto-espacio") {
      let campo = valor.replace(/[^A-Za-z0-9 ]/g, "");
      var end = campo.length;

      if (campo.substring(0, 1) == " ") {
        campo = campo.slice(0, -1);
        return campo;
      } else if (
        campo.substring(end - 2, end - 1) == " " &&
        campo.substring(end - 1, end) == " "
      ) {
        campo = campo.slice(0, -1);
        return campo;
      } else {
        return campo;
      }
    } else if (origen == "texto") {
      return valor.replace(/[^A-Za-z0-9]/g, "");
    } else if (origen == "contrasena") {
      return valor.replace(/[^A-Za-z0-9._!?@#$%&*-]*/g, "");
    } else if (origen == "numeros") {
      return valor.replace(/[^0-9]*/g, "");
    } else if (origen == "solo-texto") {
      return valor.replace(/[^A-Za-z]*/g, "");
    } else if (origen == "solo-texto-espacio") {
      let campo = valor.replace(/[^A-Za-z ]*/g, "");
      var end = campo.length;

      if (campo.substring(0, 1) == " ") {
        campo = campo.slice(0, -1);
        return campo;
      } else if (
        campo.substring(end - 2, end - 1) == " " &&
        campo.substring(end - 1, end) == " "
      ) {
        campo = campo.slice(0, -1);
        return campo;
      } else {
        return campo;
      }
    } else if (origen == "correo") {
      return valor.replace(/[^A-Za-z0-9,._@*-]*/g, "");
    }
  }

  public limpiarMonto(montoDecimal) {
    let monto = new BigNumber(montoDecimal.replace(/[^0-9]/g, ""))
      .div(100)
      .toString();

    return monto;
  }

  /*********************************** Pagos **********************************/

  ordenPagoSolicitud(
    documento: string,
    telefono: string,
    monto: string,
    concepto: string,
    tipop: string,
    cuenta: string,
    pagosolicitado: any
  ) {
    let pagoSol = null;
    if (pagosolicitado != null) {
      pagoSol = { id: pagosolicitado.id, estatus: pagosolicitado.estatus };
      //--
      //console.log("Pago Solicitado", pagosolicitado);
    }

    let firmaSeguridad = CryptoJS.SHA512(
      this.seed +
      this.token +
      telefono +
      documento +
      monto.toString() +
      concepto +
      cuenta
    )
      .toString()
      .toUpperCase();

    //--
    //console.log("firmaSeguridad", firmaSeguridad);

    let dataSolicitud = {
      identificacionReceptor: documento,
      telefonoReceptor: telefono,
      monto: monto,
      concepto: concepto,
      firmaDigital: firmaSeguridad,
      tipoReceptor: tipop,
      cuenta: cuenta,
      pagoSolicitado: pagoSol,
    };

    //--
    //console.log("dataSolicitud", dataSolicitud);
    //--
    //console.log("opciones", opciones);

    return new Promise((resolve, reject) => {
      this.http
        .post(
          environment.urlApi +
          "/instituciones/" +
          environment.idInstitucion +
          "/afiliados/" +
          this.usuario +
          "/pagos?idcanal=" +
          environment.idCanal,
          dataSolicitud,
          {
            headers: new HttpHeaders({
              "x-auth-token": this.token,
              Accept: "application/json; charset=utf-8",
            }),
          }
        )
        //.map((res: any) => res.json())
        .timeout(this.timeout)
        .subscribe(
          (data) => {
            resolve(data);
            //console.log(data);
          },
          (errorResponse: any) => {
            resolve(errorResponse);
          }
        );
    });
  }

  getPagos(tipoTran: string, tipo?: string, cuenta?: string) {
    var auth: any = this.entornoService.auth();

    var options = {
      headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
    };
    return this.http.get(
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliados/" +
      auth.user.usuario +
      "/pagos-comercios?tipo=" +
      tipo +
      "&tipotran=" +
      tipoTran +
      "&useralt=" +
      auth.user.usuario +
      "&canal=" +
      environment.idCanal +
      "&cuenta=" +
      cuenta,
      options
    );
  }

  /********************************** Retiro fondos ***********************************/
  retiroFondos(
    codCuenta: string,
    accountBanco: string,
    cod: string,
    amount: number,
    concept: string,
    idTipoCV: any
  ) {
    let firmaSeguridad = CryptoJS.SHA512(
      this.seed +
      this.token +
      cod +
      codCuenta +
      accountBanco +
      amount.toString() +
      concept
    )
      .toString()
      .toUpperCase();

    //Calidad
    // let dataSolicitud = {
    //   idCuentaRetiro: cod,
    //   codCuentaReceptor: codCuenta,
    //   cuentaReceptor: accountBanco,
    //   monto: amount,
    //   concepto: concept,
    //   idTipoCV: idTipoCV,
    //   firmaDigital: firmaSeguridad,
    // };
    //Producciòn
    let dataSolicitud = {
      id: cod,
      codCuentaReceptor: codCuenta,
      cuentaReceptor: accountBanco,
      monto: amount,
      concepto: concept,
      idTipoCV: idTipoCV,
      firmaDigital: firmaSeguridad,
    };


    return new Promise((resolve, reject) => {
      this.http
        .post(
          environment.urlApi +
          "/instituciones/" +
          environment.idInstitucion +
          "/afiliados/" +
          this.usuario +
          "/retiro?idcanal=" +
          environment.idCanal,
          dataSolicitud,
          {
            headers: new HttpHeaders({
              "x-auth-token": this.token,
              Accept: "application/json; charset=utf-8",
            }),
          }
        )
        //.map((res) => res.json())
        .timeout(this.timeout)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (errorResponse: any) => {
            resolve(errorResponse);
            console.log(errorResponse);
          }
        );
    });
  }

  setVueltoDirecto(
    usuario: string,
    cuenta: string,
    titular: string,
    monto: string,
    nacionalidad: string,
    cedula: string,
    banco: string,
    tlf: string,
    motivo: string,
    saldoDisponible: string
  ) {
    let dataSolicitud = {
      titular: titular,
      monto: monto,
      nacionalidad: nacionalidad,
      cedula: cedula,
      banco: banco,
      tlf: "58" + tlf,
      motivo: motivo,
      saldoDisponible: saldoDisponible,
      usuario: usuario,
      cuenta: cuenta,
    };
    const dataSend = {};
    return this.http
      .put<any>(
        `${environment.urlBackendV2}realizar-vuelto-directo`,
        dataSolicitud
      )
      .timeout(this.timeout);
  }
}
