import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";

import { AlertDialogComponent } from "../alert-dialog/alert-dialog.componetn";

import * as moment from "moment";
import { EntornoService } from "../../../entorno/entorno.service";

import { PagoServiciosService } from "../../../portal/pago-servicios/pago-servicios.service";

@Component({
  selector: "detalle-op-recarga-dialog",
  templateUrl: "detalle-op-recarga-dialog.component.html",
  providers: [PagoServiciosService],
})
export class DetalleOpRecargaDialogComponent {
  public title: string;
  public yesOption: string;
  public width: string;
  public height: string;

  public loading: boolean = true;

  public phone: string;
  public operadora: string;
  public monto: string;

  public saldoDisponible: any;
  public saldoDisponibleF: any;
  public telefono: any;
  public identificacion: any;
  public cuenta: any;
  public montoP: any;
  public concepto: any;
  public tipoP: any;

  public confirmacion: any;

  public user: any;

  public carga: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DetalleOpRecargaDialogComponent>,
    private service: PagoServiciosService,
    public dialog: MatDialog,
    private entorno: EntornoService,
    private router: Router
  ) {
    dialogRef.disableClose = true;
    this.title = data.title;
    this.yesOption = data.mensajeBoton;

    this.monto = data.amount;
    this.montoP = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(parseFloat(data.amount));
    this.concepto = data.concept;
    this.phone = data.phone;
    this.operadora = data.operadora;

    console.log("=>", data);
  }

  onClick(option: number) {
    this.dialogRef.close(option);
  }

  solicitarPago() {
    if (this.data.operacion == "prepago") {
      this.carga = "Por favor espere...";

      let dataRecarga = {
        operadora: this.operadora.replace(/ /g, ""),
        telefono: this.phone,
        actualTelefono: this.entorno.auth()["user"].nroTelefono,
        monto: this.data.amount,
        concepto: this.data.concept,
        cuenta: this.data.cuenta,
        purchase: this.data.purchase,
      };

      switch (this.data.company) {
        //Operadora Digitel
        case 358:
          this.service.recargaPrepago(dataRecarga).then((data) => {
            let res: any = data;

            if (res.errorMessage != undefined) {
              this.carga = null;

              if (res.errorStatus && res.errorStatus == 401) {
                this.entorno.clearSession();

                this.entorno.caduco_sesion =
                  "Su sesion ha expirado, ingrese nuevamente.";

                this.router.navigate(["/"]);
              } else {
                this.dialog
                  .open(AlertDialogComponent, {
                    data: {
                      title: "Aviso!",
                      message: res.errorMessage,
                      yes: "Cerrar",
                    },
                  })
                  .afterClosed();
              }
            } else {
              let newBalance =
                res.saldoDisponible == undefined
                  ? "NO DISPONIBLE"
                  : res.saldoDisponible;
              this.carga = null;
              const result = {
                success: true,
                reference: res.referencia,
                balance: newBalance,
                traceid: res.referencia_2,
                concepto: res.concepto,
                fechaPago: res.fechaPago,
              };
              this.dialog
                .open(AlertDialogComponent, {
                  data: {
                    title: "RECARGA REALIZADA",
                    message:
                      "Su recarga ha sido realizada satisfactoriamente \n Nro Pagado: " +
                      this.phone +
                      "\n Fecha: " +
                      result.fechaPago +
                      "\n Monto: " +
                      this.montoP +
                      "\n Referencia: " +
                      result.reference +
                      "\n Concepto: " +
                      result.concepto +
                      "\n Trace-ID: " +
                      result.traceid,
                    yes: "Aceptar",
                  },
                })
                .afterClosed()
                .subscribe((result) => {
                  this.dialog.closeAll();
                  this.router.navigate(["/portal/dashboard"]);
                  //window.location.reload();
                });
            }
          });
          break;
        //Operadora Movilnet
        case 900:
          if (this.data.company === 900) {
          }
        //Operadora Movistar Aire
        case 600:
        //Operadora SimpleTV
        case 800:
          break;
        default:
          break;
      }
    } else {
      this.carga = "Por favor espere...";

      let dataRecarga = {
        operadora: this.operadora.replace(/ /g, ""),
        telefono: this.phone,
        actualTelefono: this.entorno.auth()["user"].nroTelefono,
        monto: this.data.amount,
        concepto: this.data.concept,
        cuenta: this.data.cuenta,
        purchase: this.data.purchase,
      };

      this.service.pagoFactura(dataRecarga).then((data) => {
        let res: any = data;

        if (res.errorMessage != undefined) {
          this.carga = null;

          if (res.errorStatus && res.errorStatus == 401) {
            this.entorno.clearSession();

            this.entorno.caduco_sesion =
              "Su sesion ha expirado, ingrese nuevamente.";

            this.router.navigate(["/"]);
          } else {
            this.dialog
              .open(AlertDialogComponent, {
                data: {
                  title: "Aviso!",
                  message: res.errorMessage,
                  yes: "Cerrar",
                },
              })
              .afterClosed();
          }
        } else {
          let newBalance =
            res.saldoDisponible == undefined
              ? "NO DISPONIBLE"
              : res.saldoDisponible;
          this.carga = null;

          const result = {
            success: true,
            reference: res.referencia,
            balance: newBalance,
            traceid: res.referencia_2,
            concepto: res.concepto,
            fechaPago: res.fechaPago,
          };
          this.dialog
            .open(AlertDialogComponent, {
              data: {
                title: "PAGO DE FACTURA REALIZADO",
                message:
                  "Su pago de factura ha sido realizado satisfactoriamente",
                yes: "Aceptar",
              },
            })
            .afterClosed()
            .subscribe((result) => {
              this.dialog.closeAll();
              this.router.navigate(["/portal/dashboard"]);
              //window.location.reload();
            });
        }
      });
    }
  }
}
