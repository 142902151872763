import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";

import { environment } from "../../../environments/environment";

import { EntornoService } from "../../entorno/entorno.service";
import * as CryptoJS from "crypto-js";
import { AlertDialogComponent } from "../../shared/dialogs/alert-dialog/alert-dialog.componetn";

//

@Injectable()
export class PagoServiciosService {
  //private url_grev = "http://devnode.finhack.in:3005/api/greV";
  //private url_grev = "https://comercios.digopago.com/v2/pagos-greg5";
  private url_grev = environment.urlBackendV2 + "greV";
  private apiToken = "QWwxeDBuUnViM24mQG5kcjMkZjByM3Yzck1NWFhJeDEwMHByMw==";
  private timeout = 35000;
  private seed = "GU7RI16RKCDO5R0CHPPW949MOVXE469Y";

  constructor(
    private http: HttpClient,
    private entornoService: EntornoService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  getPagos(tipoTran: string, tipo?: string, cuenta?: string) {
    var auth: any = this.entornoService.auth();

    var options = {
      headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
    };
    return this.http.get(
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliados/" +
      auth.user.usuario +
      "/pagos-comercios?tipo=" +
      tipo +
      "&tipotran=" +
      tipoTran +
      "&useralt=" +
      auth.user.usuario +
      "&canal=" +
      environment.idCanal +
      "&cuenta=" +
      cuenta,
      options
    );
  }

  getPagosRangoFecha(
    tipoTran: string,
    fechaDesde: string,
    fechaHasta: string,
    tipo: string,
    cuenta: string,
    sucursal: number,
    caja: number,
    usuarioOperador: string
  ) {
    var auth: any = this.entornoService.auth();
    var options = {
      headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
    };

    return this.http.get(
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliados/" +
      auth.user.usuario +
      "/pagos_fecha?tipo=" +
      tipo +
      "&tipotran=" +
      tipoTran +
      "&useralt=" +
      auth.user.usuario +
      "&canal=" +
      environment.idCanal +
      "&fechaDesde=" +
      fechaDesde +
      "&fechaHasta=" +
      fechaHasta +
      "&cuenta=" +
      cuenta +
      "&sucursal=" +
      sucursal +
      "&caja=" +
      caja +
      "&operador=" +
      usuarioOperador,
      options
    );
  }

  getSecurityToken() {
    console.log(`${this.url_grev}/securityToken`)
    return this.http.post<any>(`${this.url_grev}/securityToken`, {
      apiToken: this.apiToken,
    });
  }

  validarNumero(telefono, op, operadora) {
    var auth: any = this.entornoService.auth();

    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.urlApi +
          "/instituciones/" +
          environment.idInstitucion +
          "/usuario/" +
          auth.user.usuario +
          "/validar?canal=" +
          environment.idCanal +
          "&telefono=" +
          telefono +
          "&operacion=" +
          op +
          "&operadora=" +
          operadora,
          {
            headers: new HttpHeaders({
              "x-auth-token": auth.user.token,
              Accept: "application/json; charset=utf-8",
            }),
          }
        )
        //.map((res) => res.json())
        .timeout(this.timeout)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (errorResponse: any) => {
            //resolve(this.manejarErrorRespuesta(errorResponse));
            this.dialog
              .open(AlertDialogComponent, {
                data: {
                  title: "Aviso!",
                  message: errorResponse.error.mensaje,
                  yes: "Cerrar",
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.dialog.closeAll();
                this.router.navigate(["/portal/dashboard"]);
              });
          }
        );
    });
  }

  recargaPrepago(data) {
    var auth: any = this.entornoService.auth();

    let firmaSeguridad = CryptoJS.SHA512(
      this.seed +
      auth.user.token +
      data.operadora +
      data.actualTelefono +
      data.telefono +
      data.monto +
      data.cuenta +
      data.concepto +
      data.purchase
    )
      .toString()
      .toUpperCase();

    let dataSolicitud = {
      operadora: data.operadora,
      numeroOrigen: data.actualTelefono,
      numeroDestino: data.telefono,
      montoRecarga: data.monto,
      cuentaPpal: data.cuenta,
      concepto: data.concepto,
      purchaseOrder: data.purchase,
      firmaDigital: firmaSeguridad,
    };

    console.log("data", dataSolicitud);
    console.log("token", auth.user.token);

    console.log(
      "url api",
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliados/" +
      auth.user.usuario +
      "/recarga?canal=" +
      environment.idCanal
    );

    return new Promise((resolve, reject) => {
      this.http
        .post(
          environment.urlApi +
          "/instituciones/" +
          environment.idInstitucion +
          "/afiliados/" +
          auth.user.usuario +
          "/recarga?canal=" +
          environment.idCanal,
          dataSolicitud,
          {
            headers: new HttpHeaders({
              "x-auth-token": auth.user.token,
              Accept: "application/json; charset=utf-8",
            }),
          }
        )
        //.map((res) => res.json())
        .timeout(this.timeout)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (errorResponse: any) => {
            //resolve(this.manejarErrorRespuesta(errorResponse));
            this.dialog
              .open(AlertDialogComponent, {
                data: {
                  title: "Aviso!",
                  message: errorResponse.error.mensaje,
                  yes: "Cerrar",
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.dialog.closeAll();
                this.router.navigate(["/portal/dashboard"]);
              });
          }
        );
    });
  }

  consultaPagoFactura(numero: string, operadora: string) {
    var auth: any = this.entornoService.auth();

    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.urlApi +
          "/instituciones/" +
          environment.idInstitucion +
          "/afiliados/" +
          auth.user.usuario +
          "/consultar-pagof?canal=" +
          environment.idCanal +
          "&numero=" +
          numero +
          "&operadora=" +
          operadora,
          {
            headers: new HttpHeaders({
              "x-auth-token": auth.user.token,
              Accept: "application/json; charset=utf-8",
            }),
          }
        )
        //.map((res) => res.json())
        .timeout(this.timeout)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (errorResponse: any) => {
            //resolve(this.manejarErrorRespuesta(errorResponse));
            console.log(errorResponse);
            this.dialog
              .open(AlertDialogComponent, {
                data: {
                  title: "Aviso!",
                  message: errorResponse.error.mensaje,
                  yes: "Cerrar",
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.dialog.closeAll();
                this.router.navigate(["/portal/dashboard"]);
              });
          }
        );
    });
  }

  pagoFactura(data) {
    var auth: any = this.entornoService.auth();

    let firmaSeguridad = CryptoJS.SHA512(
      this.seed +
      auth.user.token +
      data.operadora +
      data.actualTelefono +
      data.telefono +
      data.monto +
      data.cuenta +
      data.concepto +
      data.purchase
    )
      .toString()
      .toUpperCase();

    let dataSolicitud = {
      operadora: data.operadora,
      numeroOrigen: data.actualTelefono,
      numeroDestino: data.telefono,
      montoRecarga: data.monto,
      cuentaPpal: data.cuenta,
      concepto: data.concepto,
      purchaseOrder: data.purchase,
      firmaDigital: firmaSeguridad,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(
          environment.urlApi +
          "/instituciones/" +
          environment.idInstitucion +
          "/afiliados/" +
          auth.user.usuario +
          "/pago-factura?canal=" +
          environment.idCanal,
          dataSolicitud,
          {
            headers: new HttpHeaders({
              "x-auth-token": auth.user.token,
              Accept: "application/json; charset=utf-8",
            }),
          }
        )
        //.map((res) => res.json())
        .timeout(this.timeout)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (errorResponse: any) => {
            //resolve(this.manejarErrorRespuesta(errorResponse));
            this.dialog
              .open(AlertDialogComponent, {
                data: {
                  title: "Aviso!",
                  message: errorResponse.error.mensaje,
                  yes: "Cerrar",
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.dialog.closeAll();
                this.router.navigate(["/portal/dashboard"]);
              });
          }
        );
    });
  }
}
