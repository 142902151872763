import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { FormGroup } from "@angular/forms";

import { environment } from "../../../environments/environment";

import { EntornoService } from "../../entorno/entorno.service";

import * as CryptoJS from "crypto-js";
import BigNumber from "bignumber.js";

@Injectable()
export class DcardService {
  private seed = "GU7RI16RKCDO5R0CHPPW949MOVXE469Y";
  private timeout = 35000;
  public token: string;
  public usuario: string;

  constructor(
    private http: HttpClient,
    private entornoService: EntornoService
  ) {
    this.token = this.entornoService.auth()["user"].token;
    this.usuario = this.entornoService.auth()["user"].usuario;
  }

  public autorizarVenta(venta: any) {
    venta.monto = this.entornoService.limpiarMonto(venta.monto);
    let auth: any = this.entornoService.auth();

    let firmaSeguridad = CryptoJS.SHA512(
      this.seed +
        auth.user.token +
        venta.identificacion +
        venta.numeroTelefono +
        venta.monto +
        "VES" +
        venta.clave
    )
      .toString()
      .toUpperCase();

    let dataSolicitud = {
      identificacion: venta.identificacion,
      numeroTelefono: venta.numeroTelefono,
      monto: venta.monto,
      moneda: "VES",
      clave: venta.clave,
      firmaDigital: firmaSeguridad,
    };
    return this.http.post(
      environment.urlApi +
        "/instituciones/" +
        environment.idInstitucion +
        "/afiliados/" +
        auth.user.usuario +
        "/ventas",
      dataSolicitud,
      {
        headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
        observe: "response",
      }
    );
  }

  /********************************************************************/
  /********************************Limpieza final de campos*******************/
  public limpiarCampo(valor, origen) {
    if (origen == "texto-espacio") {
      let campo = valor.replace(/[^A-Za-z0-9 ]/g, "");
      var end = campo.length;

      if (campo.substring(0, 1) == " ") {
        campo = campo.slice(0, -1);
        return campo;
      } else if (
        campo.substring(end - 2, end - 1) == " " &&
        campo.substring(end - 1, end) == " "
      ) {
        campo = campo.slice(0, -1);
        return campo;
      } else {
        return campo;
      }
    } else if (origen == "texto") {
      return valor.replace(/[^A-Za-z0-9]/g, "");
    } else if (origen == "contrasena") {
      return valor.replace(/[^A-Za-z0-9._!?@#$%&*-]*/g, "");
    } else if (origen == "numeros") {
      return valor.replace(/[^0-9]*/g, "");
    } else if (origen == "solo-texto") {
      return valor.replace(/[^A-Za-z]*/g, "");
    } else if (origen == "solo-texto-espacio") {
      let campo = valor.replace(/[^A-Za-z ]*/g, "");
      var end = campo.length;

      if (campo.substring(0, 1) == " ") {
        campo = campo.slice(0, -1);
        return campo;
      } else if (
        campo.substring(end - 2, end - 1) == " " &&
        campo.substring(end - 1, end) == " "
      ) {
        campo = campo.slice(0, -1);
        return campo;
      } else {
        return campo;
      }
    } else if (origen == "correo") {
      return valor.replace(/[^A-Za-z0-9,._@*-]*/g, "");
    }
  }

  public limpiarMonto(montoDecimal) {
    let monto = new BigNumber(montoDecimal.replace(/[^0-9]/g, ""))
      .div(100)
      .toString();

    return monto;
  }

  /*********************************** Pagos **********************************/

  ordenPagoSolicitud(
    documento: string,
    telefono: string,
    monto: string,
    concepto: string,
    tipop: string,
    cuenta: string,
    pagosolicitado: any
  ) {
    let pagoSol = null;
    if (pagosolicitado != null) {
      pagoSol = { id: pagosolicitado.id, estatus: pagosolicitado.estatus };
      //--
      console.log("Pago Solicitado", pagosolicitado);
    }

    let firmaSeguridad = CryptoJS.SHA512(
      this.seed +
        this.token +
        telefono +
        documento +
        monto.toString() +
        concepto +
        cuenta
    )
      .toString()
      .toUpperCase();

    //--
    //console.log("firmaSeguridad", firmaSeguridad);

    //let cabecera: Headers = new Headers();
    //let opciones: RequestOptions;

    let dataSolicitud = {
      identificacionReceptor: documento,
      telefonoReceptor: telefono,
      monto: monto,
      concepto: concepto,
      firmaDigital: firmaSeguridad,
      tipoReceptor: tipop,
      cuenta: cuenta,
      pagoSolicitado: pagoSol,
    };

    //--
    console.log("dataSolicitud", dataSolicitud);

    // cabecera.set("Accept", "application/json; charset=utf-8");
    // cabecera.append("x-auth-token", this.token);

    // opciones = new RequestOptions({ headers: cabecera });

    //--
    //console.log("opciones", opciones);

    return new Promise((resolve, reject) => {
      this.http
        .post(
          environment.urlApi +
            "/instituciones/" +
            environment.idInstitucion +
            "/afiliados/" +
            this.usuario +
            "/pagos?idcanal=" +
            environment.idCanal,
          dataSolicitud,
          {
            headers: new HttpHeaders({
              "x-auth-token": this.token,
              Accept: "application/json; charset=utf-8",
            }),
          }
        )
        //.map((res: any) => res.json())
        .timeout(this.timeout)
        .subscribe(
          (data) => {
            resolve(data);
            //console.log(data);
          },
          (errorResponse: any) => {
            //resolve(this.manejarErrorRespuesta(errorResponse));
            console.log(errorResponse);
          }
        );
    });
  }

  getPagos(tipoTran: string, tipo?: string, cuenta?: string) {
    var auth: any = this.entornoService.auth();

    var options = {
      headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
    };
    return this.http.get(
      environment.urlApi +
        "/instituciones/" +
        environment.idInstitucion +
        "/afiliados/" +
        auth.user.usuario +
        "/pagos-comercios?tipo=" +
        tipo +
        "&tipotran=" +
        tipoTran +
        "&useralt=" +
        auth.user.usuario +
        "&canal=" +
        environment.idCanal +
        "&cuenta=" +
        cuenta,
      options
    );
  }

  /********************************** Retiro fondos ***********************************/
  retiroFondos(
    codCuenta: string,
    accountBanco: string,
    cod: string,
    amount: number,
    concept: string,
    idTipoCV: any
  ) {
    let firmaSeguridad = CryptoJS.SHA512(
      this.seed +
        this.token +
        cod +
        codCuenta +
        accountBanco +
        amount.toString() +
        concept
    )
      .toString()
      .toUpperCase();

    let dataSolicitud = {
      idCuentaRetiro: cod,
      codCuentaReceptor: codCuenta,
      cuentaReceptor: accountBanco,
      monto: amount,
      concepto: concept,
      idTipoCV: idTipoCV,
      firmaDigital: firmaSeguridad,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(
          environment.urlApi +
            "/instituciones/" +
            environment.idInstitucion +
            "/afiliados/" +
            this.usuario +
            "/retiro?idcanal=" +
            environment.idCanal,
          dataSolicitud,
          {
            headers: new HttpHeaders({
              "x-auth-token": this.token,
              Accept: "application/json; charset=utf-8",
            }),
          }
        )
        //.map((res) => res.json())
        .timeout(this.timeout)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (errorResponse: any) => {
            //resolve(this.manejarErrorRespuesta(errorResponse));
            console.log(errorResponse);
          }
        );
    });
  }

  getDcard() {
    var auth: any = this.entornoService.auth();

    var options = {
      headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
    };
    return this.http.get(
      environment.urlBackendV2 + "dcard-data/" + auth.user.documento,
      options
    );
  }

  obtenerNroSolicitudTarjeta() {
    return this.http
      .get<any>(`${environment.urlBackendV2}/nro-solicitud`)
      .timeout(this.timeout);
  }

  getUsuario(usuario: string) {
    return this.http
      .get<any>(`${environment.urlBackendV2}/usuarios/usuario/${usuario}`)
      .timeout(this.timeout);
  }

  getDireccion(id_usuario) {
    const data = {
      id_usuario,
    };

    return this.http
      .post<any>(`${environment.urlBackendV2}/dcard-direccion`, data)
      .timeout(this.timeout);
  }

  getInputsErrors(form: FormGroup) {
    let msg = "";
    let keepGoing = true;

    Object.keys(form.controls).forEach((element) => {
      if (keepGoing) {
        let errors = form.get(element).errors;

        let field = document.getElementById(element).getAttribute("data-name");

        if (errors) {
          if (errors.required) {
            msg = `Este campo es requerido: ${field}`;
            keepGoing = false;
          } else if (errors.minlength) {
            msg = `Ingrese al menos ${errors.minlength.requiredLength} dígitos`;
            keepGoing = false;
          } else if (errors.maxlength) {
            msg = `No debe ingresar mas de ${errors.maxlength.requiredLength} dígitos`;
            keepGoing = false;
          } else if (errors.wrongCode) {
            msg = "Debe ingresar un número telefonico válido";
            keepGoing = false;
          } else if (errors.wrongExpDate) {
            msg = "Debe ingresar una fecha de vencimiento válida";
            keepGoing = false;
          } else if (errors.serverError) {
            msg = errors.serverError.message;
            keepGoing = false;
          }
        }
      }
    });

    return msg;
  }

  guardarSolicitudTarjeta(
    requestNumber,
    cardHolder,
    address1,
    address2,
    address3,
    phone,
    identificacion
  ) {
    const data = {
      nroSolicitud: requestNumber,
      nombreTarjeta: cardHolder,
      direc1: address1,
      direc2: address2,
      direc3: address3,
      telefLoc: phone,
      identificacion,
    };

    return this.http
      .post<any>(`${environment.urlBackendV2}guardar-solicitud`, data)
      .timeout(this.timeout);
  }

  obtenerDCardDatos(paramSearch) {
    return this.http
      .get<any>(`${environment.urlBackendV2}dcard-data/${paramSearch}`)
      .timeout(this.timeout);
  }

  activarDCard(numberCard, cvv, expireDate) {
    const data = {
      pan: numberCard,
      cvv,
      f_venc: expireDate,
    };

    return this.http
      .put<any>(`${environment.urlBackendV2}dcard-activacion`, data)
      .timeout(this.timeout);
  }

  getDcardFee() {
    return this.http
      .get<any>(`${environment.urlBackendV2}parametros/monto_cobro_repos`)
      .timeout(this.timeout);
  }

  bloqueoDcard(option, nota, type, dCardId, userId) {
    const data = {};

    return this.http
      .put<any>(
        `${environment.urlBackendV2}dcard-${type}/${dCardId}?id_usuario=${userId}&option=${option}&nota=${nota}`,
        data
      )
      .timeout(this.timeout);
  }

  generateEdoCuenta(month) {
    const accountNumber = this.entornoService.auth()["user"].cuentas[0].numero;

    return this.http
      .get<any>(
        `${environment.urlBackendV2}estado-cuenta/${accountNumber}?mes=${month}`
      )
      .timeout(this.timeout);
  }
}
