import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Rx";

import { environment } from "../../environments/environment";
import "rxjs/Rx"; //NO QUITAR

import * as CryptoJS from "crypto-js";

export class SessionInfo {
  token: string;
  username: string;
  nombreCompleto: string;
  identificacion: string;
  correo: string;
  telefono: string;
  tiposIdentificacion: string;
  bancos: string;
  operadoras: string;
  role: string;
  afiliado: Afiliado;
}

export interface Afiliacion {
  id?: number;
  idInstitucion?: number;

  identificacion: string;
  nombreCompleto?: string;
  numeroTelefono: string;
  registroMercantil: string;
  correo: string;

  //Solo para respuestas
  otp?: string;
  otpEmitida?: Date;
  codigoVerificacion?: string;
  creado?: Date;
}

export interface PreguntasDesafio {
  id?: number;
  pregunta?: number; //En caso de que se requiera una pregunta personalizada
  respuesta: string;
  options?: any[]; // para filtrar y tener un options custom

  //Para validaciones del front
  confirmarRespuesta?: string;
  errorPreguntaVacia?: boolean;
  errorMinLength?: boolean;
  errorRepetido?: boolean;
  errorConfirmacionMatch?: boolean;
  errorEmpty?: boolean;
}
export interface ValidacionUsuario {
  usuario?: string;
  contrasena?: string;
  preguntasDesafio: PreguntasDesafio[];
}

export interface RegistroUsuario {
  afiliacion: Afiliacion;
  validacionUsuario: ValidacionUsuario;
}

export interface ListaValor {
  id: number;
  categoria?: string;
  valor: string;
  idPadre?: number;
  activo?: number;
}

export interface Direccion {
  region: ListaValor;
  estado: ListaValor;
  ciudad: ListaValor;
  municipio: ListaValor;
  parroquia: ListaValor;
  zona: ListaValor;
  calleAv?: string;
  casaEdificio?: string;
  piso?: string;
  urbanizacion?: string;
  ptoReferencia?: string;
}

export interface Afiliado {
  uuid?: string;
  identificacion: string;
  tipoPersona: string;
  numeroTelefono: string;
  ultimaConexion?: Date;
  direccion?: Direccion;
  tieneDireccion: boolean;
  tieneDatosBasicos: boolean;
  tieneRegistroMercantil: boolean;
  tieneContactos: boolean;
  tieneAfiliacionBancaria: boolean;
  tieneRecaudosValidos: boolean;
  tieneDatosCompletos: boolean;
}

@Injectable()
export class AuthService {
  public sharedInfo: any;
  private seed = "GU7RI16RKCDO5R0CHPPW949MOVXE469Y";

  constructor(private http: HttpClient, private router: Router) { }

  loadTiposIdentificacion(tipoPersona: string): Observable<any> {
    let params = new HttpParams();
    params = params.set("tipoPersona", tipoPersona);
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/clientes" +
        "/tipos-identificacion"
      ),
      { params: params }
    );
  }

  loadCodigosArea(): Observable<any> {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/operadoras-inicio"
      )
    );
  }

  actividades(): Observable<any> {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/actividades"
      )
    );
  }

  validarIdentificacion(
    identificacion: string,
    tipoCliente: string
  ): Observable<any> {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/" +
        tipoCliente +
        "/validarIdentificacion/" +
        identificacion +
        "?tipoTrans=" +
        ""
      )
    );
  }

  crearAfiliacion(afiliacion: Afiliacion, tipoCliente: string) {
    afiliacion.idInstitucion = environment.idInstitucion;

    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/" +
        tipoCliente +
        "/afiliaciones"
      ),
      afiliacion,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  sendOTP(identificacion: string, telefono: string) {
    let convert = Number(telefono);
    let body = { telefono: convert };

    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/otp"
      ),
      body,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  validarOTP(identificacion: string, otp: string) {
    let body = { otp: otp };
    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/verificacion"
      ),
      body,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  regiones(): Observable<any> {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliacion/regiones?canal=" +
        environment.idCanal
      )
    );
  }

  ciudadesmunicipios(id): Observable<any> {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliacion/" +
        id +
        "/ciudad-municipio?canal=" +
        environment.idCanal
      )
    );
  }

  consultaParroquias(id): Observable<any> {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliacion/" +
        id +
        "/parroquias?canal=" +
        environment.idCanal
      )
    );
  }

  consultaZonas(id): Observable<any> {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliacion/" +
        id +
        "/zonas?canal=" +
        environment.idCanal
      )
    );
  }

  consultaValores(): Observable<any> {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliacion/valores?canal=" +
        environment.idCanal
      )
    );
  }

  bancos(): Observable<any> {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/bancos-portal"
      )
    );
  }

  cuentasRegistradasPreviamente(identificacion: string) {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/cuentas-previas"
      )
    );
  }

  deshabilitarCuentasRegistradasPreviamente(identificacion: string) {
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/deshabilitar-cuentas-anteriores"
      )
    );
  }

  actualizarCuentasRegistradasPreviamente(
    identificacion: string,
    cuentas: any
  ) {
    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/actualizar-cuentas-previas"
      ),
      cuentas,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  deshabilitarCuentaEspecificaRegistradaPreviamente(
    identificacion: string,
    cuentas: any
  ) {
    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/deshabilitar-cuenta-especifica-anterior"
      ),
      cuentas,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  enviarDireccion(direccion: any, identificacion: string) {
    direccion.idInstitucion = environment.idInstitucion;
    direccion.monto_mov = 0;

    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/direccion"
      ),
      direccion,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  enviarCuenta(cuenta: any, identificacion: string) {
    console.log(cuenta, identificacion);
    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/datos-bancarios"
      ),
      cuenta,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  validarUsuario(identificacion: string, validacion: ValidacionUsuario) {
    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/prevalidacion"
      ),
      validacion,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  preValidarPreguntas(identificacion: string, validacion: ValidacionUsuario) {
    console.log("en servicio de preguntas");

    console.log(validacion);
    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        identificacion +
        "/prevalidacion-preguntas"
      ),
      validacion,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  registrarUsuario(registroUsuario: RegistroUsuario, tipoPersona: string) {
    let stringFirma =
      this.seed +
      registroUsuario.afiliacion.codigoVerificacion +
      environment.idCanal +
      registroUsuario.afiliacion.identificacion +
      registroUsuario.validacionUsuario.usuario +
      registroUsuario.validacionUsuario.contrasena;

    for (
      let index = 0;
      index < registroUsuario.validacionUsuario.preguntasDesafio.length;
      index++
    ) {
      const element = registroUsuario.validacionUsuario.preguntasDesafio[index];
      stringFirma = stringFirma + element.respuesta;
    }

    let firma = CryptoJS.SHA512(stringFirma).toString().toUpperCase();

    let body = {
      canal: environment.idCanal,
      usuario: registroUsuario.validacionUsuario.usuario,
      contrasena: registroUsuario.validacionUsuario.contrasena,
      preguntasDesafio: registroUsuario.validacionUsuario.preguntasDesafio,
      firmaDigital: firma,
      tipoPersona: 'B',
    };

    return this.http.post(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliaciones/" +
        registroUsuario.afiliacion.identificacion +
        "/registro"
      ),
      body,
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  loadPreguntas(identificacion: string, username?: string): Observable<any> {
    let params = new HttpParams();
    params = params.set("identificacion", identificacion);
    if (username != undefined || username != null) {
      params = params.append("usuario", username);
    }
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion.toString() +
        "/afiliados" +
        "/preguntas-desafio"
      ),
      { params: params }
    );
  }

  recuperarUsuario(identificacion: string, preguntasDesafio: any[]) {
    let endPoint =
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliados/recuperacion-usuario";
    let body = {
      canal: environment.idCanal,
      identificacion: identificacion,
      preguntasDesafio: preguntasDesafio,
    };
    return this.http.post(endPoint, body);
  }

  validarPreguntas(
    identificacion: string,
    username: string,
    preguntasDesafio: any[]
  ) {
    let endPoint =
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliados/" +
      username +
      "/preguntas-desafio";
    let body = {
      canal: environment.idCanal,
      identificacion: identificacion,
      preguntasDesafio: preguntasDesafio,
    };
    return this.http.post(endPoint, body);
  }

  restablecerContrasena(
    identificacion: string,
    contrasena: string,
    username: string,
    preguntasDesafio: any[]
  ) {
    let endPoint =
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliados/" +
      username +
      "/restablecimiento-contrasena";
    let body = {
      canal: environment.idCanal,
      identificacion: identificacion,
      contrasena: contrasena,
      preguntasDesafio: preguntasDesafio,
    };
    return this.http.post(endPoint, body);
  }

  listaTipoRecaudos(tipoCliente: string) {
    return this.http.get(
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/tiposRecaudos/" +
      tipoCliente.trim().toLowerCase(),
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  cargarArchivoAfiliacion(
    tipo: string,
    archivo: File,
    documento: string,
    tipoAfi: string
  ) {
    let formData: FormData = new FormData();
    formData.append("file", archivo, archivo.name);

    return this.http.post(
      environment.urlApi +
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliaciones/registro-recaudos/" +
      tipoAfi +
      "/carga/" +
      tipo +
      "?idEnDocumento=" +
      documento,
      formData, //documento
      {
        headers: new HttpHeaders({ Accept: "application/json; charset=utf-8" }),
      }
    );
  }

  buscarArchivoParaVisualizar(idEnDocumento: string) {
    let params = new HttpParams();
    params = params.set("idEnDocumento", idEnDocumento);
    return this.http.get(
      environment.urlApi.concat(
        "/instituciones/" +
        environment.idInstitucion +
        "/afiliaciones/registro-recaudos/visualizar"
      ),
      { params: params }
    );
  }

  logoutWithSesionActive(sessionExpired: boolean) {
    let endPoint = environment.urlApi.concat(
      "/instituciones/" +
      environment.idInstitucion.toString() +
      "/afiliados/" +
      localStorage.getItem("username") +
      "/cierre-sesion"
    );
    let headers = new HttpHeaders({
      "X-Auth-Token": localStorage.getItem("token"),
    });
    let tipoCliente = localStorage.getItem("tipoCliente").toLowerCase();
    this.http.post(endPoint, null, { headers: headers }).subscribe(
      (data) => {
        this.destroySession();
        this.router.navigate(["/login/" + tipoCliente], {
          queryParams: { expired: sessionExpired },
        });
      },
      (error) => {
        this.destroySession();
        this.router.navigate(["/login/" + tipoCliente], {
          queryParams: { expired: sessionExpired },
        });
      }
    );
  }

  // activate(uuid: string, username: string, password: string): Observable<any> {
  //     let body = {
  //         uuid: uuid,
  //         password: password
  //     };

  //     return this.http.post(environment.apiUrl.concat('/users/').concat(username).concat('/activate'), body,
  //         {
  //             observe: 'response'
  //         });
  // }

  // findActivation(uuid: string): Observable<any> {
  //     return this.http.get(environment.apiUrl.concat('/user-activations/').concat(uuid));
  // }

  initSession(sessionInfo: SessionInfo) {
    localStorage.setItem("token", sessionInfo.token);
    localStorage.setItem("username", sessionInfo.username);
    localStorage.setItem("nombreCompleto", sessionInfo.nombreCompleto);
    localStorage.setItem("identificacion", sessionInfo.identificacion);
    localStorage.setItem("correo", sessionInfo.correo);
    localStorage.setItem("telefono", sessionInfo.telefono);
    localStorage.setItem(
      "tiposIdentificacion",
      sessionInfo.tiposIdentificacion
    );
    localStorage.setItem("bancos", sessionInfo.bancos);
    localStorage.setItem("operadoras", sessionInfo.operadoras);
    localStorage.setItem("afiliado", JSON.stringify(sessionInfo.afiliado));
    localStorage.setItem("role", sessionInfo.role);
  }

  destroySession() {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("nombreCompleto");
    localStorage.removeItem("identificacion");
    localStorage.removeItem("correo");
    localStorage.removeItem("telefono");
    localStorage.removeItem("tiposIdentificacion");
    localStorage.removeItem("bancos");
    localStorage.removeItem("operadoras");
    localStorage.removeItem("role");
    localStorage.removeItem("afiliado");
  }

  getSessionInfo(): SessionInfo {
    return {
      token: localStorage.getItem("token"),
      username: localStorage.getItem("username"),
      nombreCompleto: localStorage.getItem("nombreCompleto"),
      identificacion: localStorage.getItem("identificacion"),
      correo: localStorage.getItem("correo"),
      telefono: localStorage.getItem("telefono"),
      tiposIdentificacion: localStorage.getItem("tiposIdentificacion"),
      bancos: localStorage.getItem("bancos"),
      operadoras: localStorage.getItem("operadoras"),
      role: localStorage.getItem("role"),
      afiliado: JSON.parse(localStorage.getItem("afiliado")),
    };
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  }

  updateSharedInfo(data: any) {
    this.sharedInfo = data;
    localStorage.setItem("sharedInfo", JSON.stringify(data));
  }

  destroySharedInfo() {
    this.sharedInfo = undefined;
    localStorage.removeItem("sharedInfo");
  }

  getSharedInfo() {
    return JSON.parse(localStorage.getItem("sharedInfo"));
  }
}
