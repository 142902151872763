import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { EntornoService } from "../../../entorno/entorno.service";
import { DcardService } from "../../../portal/dcard/dcard.service";
import { RetirarSaldoService } from "../../../portal/retirar-saldo/retirar-saldo.service";
import { AlertDialogComponent } from "../alert-dialog/alert-dialog.componetn";

@Component({
  selector: "app-termsvt",
  templateUrl: "./termsvt.component.html",
  styleUrls: ["./termsvt.component.css"],
  providers: [RetirarSaldoService],
})
export class TermsvtComponent implements OnInit {
  public title: string;
  public yesOption: string;
  public width: string;
  public height: string;

  public loading: boolean = true;

  public user: any;

  public carga: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TermsvtComponent>,
    private router: Router,
    public dialog: MatDialog,
    private retirarSaldoService: RetirarSaldoService,
    private entornoService: EntornoService
  ) {
    this.title = data.title;
    this.yesOption = data.mensajeBoton;
  }

  ngOnInit() {}

  onClick(option: number) {
    if (option === 1) {
      this.router.navigate(["/"]);
      this.dialogRef.close(option);
    }
  }

  confirm() {
    this.carga = "Cargando...";
    this.retirarSaldoService
      .setTerminosVT(this.entornoService.auth()["user"].usuario)
      .subscribe(
        (resp) => {
          console.log(resp);
          this.carga = null;
          this.dialog
            .open(AlertDialogComponent, {
              data: {
                title: "Términos Actualizados con éxito!",
                message: "Términos de Vuelto Directo Actualizados con éxito",
                yes: "Cerrar",
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.dialogRef.close();
            });
        },
        (err) => {
          console.log(err);
          this.carga = null;
          this.dialog
            .open(AlertDialogComponent, {
              data: {
                title: "Aviso!",
                message: `${err.mensaje}`,
                yes: "Cerrar",
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.dialogRef.close();
            });
        }
      );
  }
}
