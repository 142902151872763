import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";

import { SolicitudDialogComponent } from "../solicitud-dialog/solicitud-dialog.component";

import * as moment from "moment";
import { EntornoService } from "../../../entorno/entorno.service";

import { DcardService } from "../../../portal/dcard/dcard.service";

@Component({
  selector: "intro-solicitud-dialog",
  templateUrl: "intro-solicitud-dialog.component.html",
  providers: [DcardService],
})
export class IntroSolicitudDialogComponent {
  public title: string;
  public yesOption: string;
  public width: string;
  public height: string;

  public loading: boolean = true;

  public fecha: string;

  public saldoDisponible: any;
  public saldoDisponibleF: any;

  public user: any;

  public carga: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IntroSolicitudDialogComponent>,
    private service: DcardService,
    public dialog: MatDialog,
    private entorno: EntornoService,
    private router: Router
  ) {
    dialogRef.disableClose = true;
    this.title = data.title;
    this.yesOption = data.mensajeBoton;
  }

  onClick(option: number) {
    this.dialogRef.close(option);
  }

  confirm() {
    this.dialogRef.close();
    this.dialog
      .open(SolicitudDialogComponent, {
        width: "500px",
        data: {
          title: "Solicitud de tarjeta D CARD",
          mensajeBoton: "Cancelar",
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((result) => {
        //this.router.navigate(["/portal/dashboard"]);
      });
  }
}
