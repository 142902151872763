import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";

import { AlertDialogComponent } from "../alert-dialog/alert-dialog.componetn";
import { ContratoDialogComponent } from "../contrato-dialog/contrato-dialog.component";

import * as moment from "moment";
import { EntornoService } from "../../../entorno/entorno.service";

import { DcardService } from "../../../portal/dcard/dcard.service";

@Component({
  selector: "solicitud-dialog",
  templateUrl: "solicitud-dialog.component.html",
  providers: [DcardService],
})
export class SolicitudDialogComponent {
  public title: string;
  public yesOption: string;
  public width: string;
  public height: string;

  public loading: boolean = true;

  public fecha: string;

  public saldoDisponible: any;
  public saldoDisponibleF: any;

  public user: any;
  public userId: any;

  public carga: any = null;

  personalData: any;
  address: any;

  solicitudTDC: FormGroup = this.fb.group({
    nombre: ["", [Validators.required]],
    direccion1: ["", [Validators.required]],
    direccion2: ["", [Validators.required]],
    direccion3: ["", [Validators.required]],
    telefono: [
      "",
      [Validators.required, Validators.minLength(11), this.phoneValidation],
    ],
  });

  content = {
    icon: "../../../../assets/images/dcard/solicitud.png",
    title:
      "Apreciado usuario, DIGOPAGO le invita a verificar si usted es apto para el proceso de solicitud de Tarjeta de Débito para que pueda pagar con su saldo DIGO, en cualquier punto de ventas",
    solicitud: 0,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SolicitudDialogComponent>,
    private service: DcardService,
    public dialog: MatDialog,
    private entorno: EntornoService,
    private router: Router
  ) {
    dialogRef.disableClose = true;
    this.title = data.title;
    this.yesOption = data.mensajeBoton;

    this.saldoDisponible = this.entorno.saldoDisponiblePagar;
    this.saldoDisponibleF = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(parseFloat(this.saldoDisponible));

    this.user = this.entorno.auth()["user"].usuario;

    this.fecha = moment(new Date()).format("DD/MM/YYYY HH:MM:SS");

    this.getSolicitud();
    this.llenarDatos();
  }

  onClick(option: number) {
    this.dialogRef.close(option);
  }

  getSolicitud() {
    this.carga = "Por Favor Espere...";
    this.service.obtenerNroSolicitudTarjeta().subscribe(
      (resp) => {
        this.content.solicitud = resp.nroSolicitud;
        this.carga = null;
      },
      (err) => {
        this.dialog
          .open(AlertDialogComponent, {
            data: {
              title: "Aviso!",
              message: "En estos momentos el servicio no esta disponible",
              yes: "Cerrar",
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.dialogRef.close();
          });
      }
    );
  }

  llenarDatos() {
    // incluir loading

    this.service.getUsuario(this.entorno.auth()["user"].usuario).subscribe(
      (usuario) => {
        this.userId = usuario.id;
        this.carga = "Por Favor Espere...";
        this.service.getDireccion(usuario.id).subscribe(
          (resp) => {
            this.carga = null;
            this.address = resp;
            //this.servicio.userData.ciudad = `${resp.ciudad}, ${resp.estado}`;
            this.setAddress();
          },
          (err) => {
            console.log(err);
            this.dialog
              .open(AlertDialogComponent, {
                data: {
                  title: "Aviso!",
                  message: "En estos momentos el servicio no esta disponible",
                  yes: "Cerrar",
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.dialogRef.close();
              });
          }
        );
      },
      (err) => {
        console.log(err);
        this.dialog
          .open(AlertDialogComponent, {
            data: {
              title: "Aviso!",
              message: "En estos momentos el servicio no esta disponible",
              yes: "Cerrar",
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.dialogRef.close();
          });
      }
    );
  }

  phoneValidation(control: FormControl) {
    const currentPhone = control.value.substr(0, 4);
    let codes = [
      "0212",
      "0412",
      "0414",
      "0416",
      "0424",
      "0426",
      "0234",
      "0235",
      "0238",
      "0239",
      "0240",
      "0241",
      "0242",
      "0243",
      "0244",
      "0245",
      "0246",
      "0247",
      "0249",
      "0251",
      "0252",
      "0253",
      "0254",
      "0255",
      "0256",
      "0258",
      "0259",
      "0261",
      "0262",
      "0263",
      "0264",
      "0265",
      "0266",
      "0267",
      "0268",
      "0269",
      "0271",
      "0271",
      "0272",
      "0273",
      "0274",
      "0275",
      "0276",
      "0277",
      "0278",
      "0279",
      "0281",
      "0282",
      "0283",
      "0284",
      "0285",
      "0286",
      "0287",
      "0288",
      "0289",
      "0291",
      "0292",
      "0293",
      "0294",
      "0295",
    ];
    if (codes.indexOf(currentPhone) === -1) {
      return {
        wrongCode: true,
      };
    }
    return null;
  }

  setAddress() {
    this.solicitudTDC.setValue({
      nombre: this.limpiarString(this.address.NombreApellido, 26),
      direccion1: this.limpiarString(this.address.dirc1, 30),
      direccion2: this.limpiarString(this.address.dirc2, 30),
      direccion3:
        this.limpiarString(this.address.dirc3, 25) +
        " " +
        this.address.codigo_postal,
      telefono: "",
    });
  }

  limpiarString(str: string, qty: number) {
    let replacing = str.replace(/[^a-zA-Z0-9 ]/g, "");
    if (qty === 26) {
      replacing = str.replace(/[^a-zA-Z ]/g, "");
    }
    const clean = replacing.substring(0, qty);
    return clean;
  }

  verContrato() {
    this.dialog
      .open(ContratoDialogComponent, {
        width: "500px",
        data: {
          title: "Contrato D-Card",
          mensajeBoton: "Regresar",
          nombre: this.address.NombreApellido,
          ciudad: this.address.ciudad,
          documento: this.address.Cédula,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((result) => {
        //this.router.navigate(["/portal/dashboard"]);
      });
  }

  confirm() {
    this.carga = "Cargando...";
    const msg = this.service.getInputsErrors(this.solicitudTDC);

    if (msg !== "") {
      this.dialog
        .open(AlertDialogComponent, {
          data: {
            title: "Aviso!",
            message: `${msg}`,
            yes: "Cerrar",
          },
        })
        .afterClosed()
        .subscribe(() => (this.carga = null));
    } else {
      this.service
        .guardarSolicitudTarjeta(
          this.content.solicitud,
          this.solicitudTDC.value.nombre,
          this.solicitudTDC.value.direccion1,
          this.solicitudTDC.value.direccion2,
          this.solicitudTDC.value.direccion3,
          this.solicitudTDC.value.telefono,
          this.entorno.auth()["user"].documento
        )
        .subscribe(
          (resp) => {
            console.log(resp);
            this.carga = null;
            this.getDcardStatus();
            this.dialog
              .open(AlertDialogComponent, {
                data: {
                  title: "Exito!",
                  message: resp.mensaje,
                  yes: "Cerrar",
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.dialogRef.close();
                this.router.navigate(["/portal/dashboard"]);
              });
          },
          (err) => {
            console.log(err);
            this.carga = null;
            this.dialog
              .open(AlertDialogComponent, {
                data: {
                  title: "Aviso!",
                  message: `${err.mensaje}`,
                  yes: "Cerrar",
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.dialogRef.close();
              });
          }
        );
    }
  }

  getDcardStatus() {
    this.carga = "Cargando...";
    this.service
      .obtenerDCardDatos(this.entorno.auth()["user"].documento)
      .subscribe(
        (result) => {
          // this._services.userData.dcardStatus = result.staPan;
          // this._services.userData.dcard = result.pan;
          // this._services.userData.dcardId = result.id;
          console.log("dcardstatus", result.staPan);
          this.carga = null;
        },
        (err) => {
          this.carga = null;
          if (err.error.trim() === "14 Tarjeta Invalida") {
            // this._services.userData.dcardStatus = 0;
            console.log("14 Tarjeta Invalida");
          } else {
            console.log(err);
          }
        }
      );
  }
}
