import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";

import { SolicitudDialogComponent } from "../solicitud-dialog/solicitud-dialog.component";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { EntornoService } from "../../../entorno/entorno.service";

import { DcardService } from "../../../portal/dcard/dcard.service";
import { AlertDialogComponent } from "../alert-dialog/alert-dialog.componetn";

@Component({
  selector: "bloqueo-dialog",
  templateUrl: "bloqueo-dialog.component.html",
  providers: [DcardService],
})
export class BloqueoDialogComponent {
  public title: string;
  public yesOption: string;
  public width: string;
  public height: string;

  public loading: boolean = true;

  public fecha: string;

  public saldoDisponible: any;
  public saldoDisponibleF: any;

  public user: any;

  public carga: any = null;

  content = {
    title: `Ahora podrás reportar el ${this.data.title} de tu D-Card e iniciar la gestión para su reposición`,
    title1: `Ahora podrás reportar la ${this.data.title} de tu D-Card e iniciar la gestión para su reposición`,
    subtitle: "",
    solicitud: "",
  };

  optionT: any = null;
  tarjeta = "";
  fee = "";
  answer = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<BloqueoDialogComponent>,
    private service: DcardService,
    public dialog: MatDialog,
    private entorno: EntornoService,
    private router: Router
  ) {
    dialogRef.disableClose = true;
    this.title = data.title;
    this.yesOption = data.mensajeBoton;

    this.tarjeta = this.getCard();
    this.getFee();
  }

  onClick(option: number) {
    this.dialogRef.close(option);
  }

  bloqueo() {
    if (this.answer !== "") {
      this.carga = "Cargando...";

      this.service.getUsuario(this.entorno.auth()["user"].usuario).subscribe(
        (usuario) => {
          this.service
            .bloqueoDcard(
              this.answer,
              this.data.tipo,
              this.data.tipo.toLowerCase(),
              this.data.dcard.id,
              usuario.id
            )
            .subscribe(
              (resp) => {
                this.carga = null;
                this.dialogRef.close();
                this.getDcardStatus();

                this.dialog
                  .open(AlertDialogComponent, {
                    data: {
                      title: "Aviso",
                      message: resp.mensaje,
                      yes: "Aceptar",
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.router.navigate(["/portal/dashboard"]);
                  });
              },
              (err) => {
                this.carga = null;
                this.dialog
                  .open(AlertDialogComponent, {
                    data: {
                      title: "Aviso",
                      message: err.mensaje,
                      yes: "Cerrar",
                    },
                  })
                  .afterClosed();
              }
            );
        },
        (err) => {
          this.carga = null;
          this.dialog
            .open(AlertDialogComponent, {
              data: {
                title: "Aviso",
                message: err.mensaje,
                yes: "Cerrar",
              },
            })
            .afterClosed();
        }
      );
    } else {
      this.dialog
        .open(AlertDialogComponent, {
          data: {
            title: "Aviso",
            message:
              "Estimado usuario por favor indique si desea la reposición de su tarjeta",
            yes: "Cerrar",
          },
        })
        .afterClosed();
    }
  }

  getDcardStatus() {
    this.carga = "Cargando...";
    this.service
      .obtenerDCardDatos(this.entorno.auth()["user"].documento)
      .subscribe(
        (result) => {
          // this._services.userData.dcardStatus = result.staPan;
          // this._services.userData.dcard = result.pan;
          // this._services.userData.dcardId = result.id;
          console.log("dcardstatus", result.staPan);
          this.carga = null;
        },
        (err) => {
          this.carga = null;
          if (err.error.trim() === "14 Tarjeta Invalida") {
            // this._services.userData.dcardStatus = 0;
            console.log("14 Tarjeta Invalida");
          } else {
            console.log(err);
          }
        }
      );
  }

  getCard() {
    const card = this.data.dcard.pan;
    const first = card.substring(0, 4);
    const last = card.substring(card.length - 4);
    return `${first}-****-****-${last}`;
  }

  getFee() {
    this.carga = "Cargando...";

    this.service.getDcardFee().subscribe(
      (resp) => {
        this.carga = null;
        this.fee = resp.valor;
      },
      (err) => {
        this.carga = null;
        console.log(err);
        this.dialog
          .open(AlertDialogComponent, {
            data: {
              title: "Aviso",
              message:
                "En estos momentos este servicio no esta disponible, intente mas tarde",
              yes: "Cerrar",
            },
          })
          .afterClosed();
      }
    );
  }

  answerSelected(e) {
    this.answer = e.value;
  }
}
