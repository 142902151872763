import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";

import { AlertDialogComponent } from "../alert-dialog/alert-dialog.componetn";

import * as moment from "moment";
import { EntornoService } from "../../../entorno/entorno.service";

import { PagarService } from "../../../portal/pagar/pagar.service";

@Component({
  selector: "detalle-operacion-dialog",
  templateUrl: "detalle-operacion-dialog.component.html",
  providers: [PagarService],
})
export class DetalleOperacionDialogComponent {
  public title: string;
  public yesOption: string;
  public width: string;
  public height: string;

  public loading: boolean = true;

  public fecha: string;
  public monto: string;

  public saldoDisponible: any;
  public saldoDisponibleF: any;
  public telefono: any;
  public identificacion: any;
  public cuenta: any;
  public montoP: any;
  public concepto: any;
  public tipoP: any;

  public confirmacion: any;

  public user: any;

  public carga: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DetalleOperacionDialogComponent>,
    private service: PagarService,
    public dialog: MatDialog,
    private entorno: EntornoService,
    private router: Router
  ) {
    dialogRef.disableClose = true;
    this.title = data.title;
    this.yesOption = data.mensajeBoton;

    this.saldoDisponible = data.pagar.cuenta.saldoDisponible;
    this.saldoDisponibleF = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(parseFloat(this.saldoDisponible));
    this.telefono = data.pagar.operadora + data.pagar.telefono;
    this.identificacion = data.pagar.nacionalidad + data.pagar.identificacion;
    this.cuenta = data.pagar.cuenta.numero;

    this.monto = data.pagar.monto;
    this.montoP = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(parseFloat(data.pagar.monto));
    this.concepto = data.pagar.concepto;
    this.tipoP = data.tipoPersona;

    this.confirmacion = data.confirmacion;

    this.user = this.entorno.auth()["user"].usuario;

    this.fecha = moment(new Date()).format("DD/MM/YYYY HH:MM:SS");
  }

  onClick(option: number) {
    this.dialogRef.close(option);
  }

  pagar() {
    this.dialog
      .open(AlertDialogComponent, {
        data: {
          title: "PAGAR",
          message: "No podrá deshacer esta opción",
          yes: "Aceptar",
          no: "Cancelar",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result === 1) {
          if (this.monto != null) {
            let monto = this.service.limpiarMonto(this.montoP);
            this.monto = monto;
          }

          this.carga = "Por favor espere...";

          if (this.confirmacion == 1 || this.confirmacion == 3) {
            if (this.cuenta == undefined) {
              this.cuenta = "";
            }

            this.service
              .ordenPagoSolicitud(
                this.identificacion,
                this.telefono,
                this.monto,
                this.concepto,
                this.tipoP,
                this.cuenta,
                null
              )
              .then((data) => {
                let res: any = data;

                this.entorno.saldoDisponiblePagar = res.saldoDisponible;

                if (res.errorMessage != undefined || res.error) {
                  if (res.errorStatus && res.errorStatus == 401) {
                    this.dialog
                      .open(AlertDialogComponent, {
                        data: {
                          title: "Aviso!",
                          message:
                            "Tu sesión ha caducado, por favor ingresa nuevamente",
                          yes: "Cerrar",
                        },
                      })
                      .afterClosed();
                    this.entorno.clearSession();

                    this.entorno.caduco_sesion =
                      "Su sesion ha expirado, ingrese nuevamente.";

                    this.router.navigate(["/"]);
                    return;
                  } else {
                    this.dialog
                      .open(AlertDialogComponent, {
                        data: {
                          title: "ERROR",
                          message:
                            "Ocurrió un error, intente más tarde por favor.",
                          yes: "Cancelar",
                        },
                      })
                      .afterClosed()
                      .subscribe(() => {
                        this.dialog.closeAll();
                        this.router.navigate(["/portal/dashboard"]);
                      });
                  }
                } else {
                  let newBalance =
                    res.saldoDisponible == undefined
                      ? "NO DISPONIBLE"
                      : res.saldoDisponible;
                  const result = {
                    success: true,
                    reference: res.referencia,
                    balance: newBalance,
                    dailyAvailable: res.limiteDiarioDisponible,
                    cuenta: this.cuenta,
                  };
                  this.dialog
                    .open(AlertDialogComponent, {
                      data: {
                        title: "PAGO REALIZADO",
                        message: "Su pago ha sido realizado satisfactoriamente",
                        yes: "Aceptar",
                      },
                    })
                    .afterClosed()
                    .subscribe((result) => {
                      this.actualizar();
                      this.dialog.closeAll();
                      this.router.navigate(["/portal/dashboard"]);
                      //window.location.reload();
                    });
                }
              });
          }
        }
        if (result === 0) {
          this.dialog.closeAll();
        }
      });
  }

  actualizar() {
    const acc = this.entorno.auth()["user"].cuentas;
    if (acc) {
      for (let i = 0; i < acc.length; i++) {
        acc[i].saldoDisponible = this.entorno.limpiarMonto(
          acc[i].saldoDisponible.toString()
        );
      }
    }

    this.loading = true;

    this.entorno.obtenerBalance(acc).then((data) => {
      let res: any = data;

      if (res.errorMessage != undefined) {
        if (res.errorStatus && res.errorStatus == 401) {
          this.loading = false;
          this.dialog
            .open(AlertDialogComponent, {
              data: {
                title: "Aviso!",
                message: "Tu sesión ha caducado, por favor ingresa nuevamente",
                yes: "Cerrar",
              },
            })
            .afterClosed();
          this.entorno.clearSession();

          this.entorno.caduco_sesion =
            "Su sesion ha expirado, ingrese nuevamente.";

          this.router.navigate(["/"]);
          return;
        } else {
          this.loading = false;
          this.dialog
            .open(AlertDialogComponent, {
              data: {
                title: "Aviso!",
                message: res.errorMessage,
                yes: "Cerrar",
              },
            })
            .afterClosed();
          return;
        }
      } else if (res && res.length == 0.0) {
        this.dialog
          .open(AlertDialogComponent, {
            data: {
              title: "Aviso!",
              message: "No ha emitido algún pago",
              yes: "Cerrar",
            },
          })
          .afterClosed();
        return;
      } else {
        // let cuentas = JSON.parse(localStorage.getItem("cuentas"));
        // cuentas[0].saldoDisponible = "10";
        console.log("aca es=>", data);
        localStorage.setItem("cuentas", JSON.stringify(res));

        this.entorno.auth()["user"].cuentas = res;

        if (this.entorno.infoUsuario.tipo == "OPERADOR") {
          this.entorno.infoUsuario.balance =
            this.entorno.auth()["user"].cuentas[0].saldoDisponible;
          this.saldoDisponible =
            this.entorno.auth()["user"].cuentas[0].saldoDisponible;
        } else {
          for (let i = 0; i < this.entorno.auth()["user"].cuentas.length; i++) {
            if (
              this.entorno.auth()["user"].cuentas[i].idTipo == 1 ||
              this.entorno.auth()["user"].cuentas[i].idTipo == 3
            ) {
              this.entorno.infoUsuario.balance =
                this.entorno.auth()["user"].cuentas[i].saldoDisponible;
              this.saldoDisponible = this.entorno.pipeDecimalBigNumber(
                this.entorno
                  .auth()
                  ["user"].cuentas[i].saldoDisponible.toString()
              );
              // this.saldoBloqueado = this.entorno.pipeDecimalBigNumber(
              //   this.entorno.auth()["user"].cuentas[i].saldoBloqueado.toString()
              // );
            }
            this.entorno.auth()["user"].cuentas[i].saldoDisponible =
              this.entorno.pipeDecimalBigNumber(
                this.entorno
                  .auth()
                  ["user"].cuentas[i].saldoDisponible.toString()
              );
          }
        }
      }

      // this.auth = this.entorno.auth();
      // console.log(res, this.entorno.infoUsuario);
      // this.loading = false;
    });
  }
}
