import { Component } from "@angular/core";

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "venta",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/venta.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "compartir",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/COMPARTIR.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "recibidos",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/recibidos.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "emitidos",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/emitidos.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "solicitud",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/SOLICITA PAGO.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "cargaM",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/F2C AZUL.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "pagar",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/P2P.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "cargarSaldo",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/cargar.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "perfil",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/perfil.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "consultarT",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/CONSULTAR.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "retirarSaldo",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/retirar.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "consultaM",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/assignment_icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "dcard",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/dcard/dcard.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "servicios",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/servicios.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "retirarSaldoNew",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/retirar_saldo.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "cargarSaldo1",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/recargar_new.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "pagarNew",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/pagar_new.svg"
      )
    );
  }
}

export const SESSION_EXPIRED = 995;
export const EXP_CODE = 153;
export const HTTP_SECURITY = 401;

export function responseErrorParser(respuesta: any): {
  errorStatus: any;
  errorMessage: any;
  expirationCode: any;
  codigo: any;
} {
  let cuerpoError = {
    errorStatus: "",
    errorMessage: "",
    expirationCode: 0,
    codigo: 0,
  };

  if (respuesta.status == 0) {
    cuerpoError.errorStatus = respuesta.status.toString();
    cuerpoError.errorMessage = "Fallo en la conexión";
  } else if (respuesta.name && respuesta.name == "TimeoutError") {
    cuerpoError.errorStatus = "timeout";
    cuerpoError.errorMessage = "Fallo en la conexión, tiempo límite excedido";
  } else if (respuesta.error && respuesta.error.mensaje) {
    cuerpoError.errorStatus = respuesta.error.estatus;
    cuerpoError.errorMessage = respuesta.error.mensaje;
    cuerpoError.codigo = respuesta.error.codigo;

    if (
      respuesta.error.codigo != undefined &&
      respuesta.error.codigo == EXP_CODE
    ) {
      cuerpoError.expirationCode = respuesta.error.codigo;
    }
  } else {
    try {
      if (respuesta._body && JSON.parse(respuesta._body).mensaje) {
        cuerpoError.errorStatus = respuesta.status.toString();
        cuerpoError.errorMessage = JSON.parse(respuesta._body).mensaje;
        cuerpoError.codigo = JSON.parse(respuesta._body).codigo;

        if (
          JSON.parse(respuesta._body).codigo != undefined &&
          JSON.parse(respuesta._body).codigo == EXP_CODE
        ) {
          cuerpoError.expirationCode = JSON.parse(respuesta._body).codigo;
        }
      }
    } catch (e) { }
  }

  if (cuerpoError.errorMessage.length == 0) {
    cuerpoError.errorStatus = "unknown";
    cuerpoError.errorMessage =
      "Error de comunicación, intente acceder nuevamente";
  }
  return cuerpoError;
}
